<template>
  <div class="container">
    <div class="doc-orders mt-4">
      <h4 style="text-align: center">Приказы (временная страница)</h4>




      <div class="row mt-4 mb-4">
        <div class="col-md-12">
          <Button type="button" icon="pi pi-plus" label="Добавить приказ" @click="openAddDocOrdersDialog"/>
        </div>
      </div>

      <DataTable :value="docOrders_form.docOrdersInfo" :paginator="true" :rows="10"
                 paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                 :rowsPerPageOptions="[10,20,50]"
                 filterDisplay="menu"
                 :globalFilterFields="['order_number', 'order_reason_ru']"
                 v-model:filters="filters"
                 currentPageReportTemplate="Показано {last} из {totalRecords}"
                 stripedRows responsiveLayout="scroll">

        <template #header>
          <div class="d-flex justify-content-between">

            <span class="p-input-icon-left">
              <i class="pi pi-search"/>
              <InputText v-model="filters['global'].value" placeholder="Поиск"/>
            </span>

          </div>

        </template>

        <Column field="order_number" header="Номер приказа"></Column>
        <Column header="Дата">
          <template #body="{data}">
            {{ convertTimestampToDate(data.doc_order_date) }}
          </template>
        </Column>
        <Column header="Тип приказа" field="docOrderType.name_ru">
          <template #body="{data}">
            {{ data.docOrderType?.name_ru }}
          </template>

          <template #filter="{ filterModel }">
            <Dropdown v-model="filterModel.value" :options="docOrders_form.docOrderType" optionValue="name_ru" placeholder="Выберите" class="p-column-filter" showClear>
              <template #value="slotProps">
                <span v-if="slotProps.value">{{ slotProps.value }}</span>
                <span v-else>{{ slotProps.placeholder }}</span>
              </template>
              <template #option="slotProps">
                {{slotProps.option.name_ru}}
              </template>
            </Dropdown>
          </template>
        </Column>
        <Column header="Подтип приказа" field="docOrderSubType.name_ru">
          <template #body="{data}">
            <div v-if="data.docOrderSubType">
              {{ data.docOrderSubType?.name_ru }}
            </div>
            <div v-else>
              -
            </div>

          </template>
          <template #filter="{ filterModel }">
            <Dropdown v-model="filterModel.value" :options="docOrders_form.docOrderSubType" optionValue="name_ru" placeholder="Выберите" class="p-column-filter" showClear>
              <template #value="slotProps">
                <span v-if="slotProps.value">{{ slotProps.value }}</span>
                <span v-else>{{ slotProps.placeholder }}</span>
              </template>
              <template #option="slotProps">
                <span>{{ slotProps.option.name_ru }}</span>
              </template>
            </Dropdown>
          </template>
        </Column>
        <Column field="order_note_ru" header="Примечание"></Column>
        <Column field="order_reason_ru" header="Основание"></Column>

        <Column header="Студенты">
          <template #body="{data}">
            <div v-if="data.docOrderStudents?.length == 0">
              <router-link :to="'/doc-orders-students'+ '?doc_order_id='+ +data.id + '&doc_order_type_id=' + data.doc_order_type_id + '&doc_order_sub_type_id='+ data.doc_order_sub_type_id" target="_blank">
                Добавить студентов
              </router-link>
            </div>
            <div v-else>
              <router-link :to="'/doc-orders-students'+ '?doc_order_id='+ +data.id + '&doc_order_type_id=' + data.doc_order_type_id + '&doc_order_sub_type_id='+ data.doc_order_sub_type_id" target="_blank">
                Список студентов
              </router-link>
            </div>
<!--            <Button icon="pi pi-user-plus" @click="openAddSupervisorDialog(data.hours, data.education_program_id, data.study_level_id)"/>-->

          </template>
        </Column>
        <Column header="Действия">

          <template #body="{data}">

            <div class="mb-2">

              <Button class="p-button-warning" icon="pi pi-pencil" @click="openEditDocOrdersDialog(data.id)"/>

            </div>

            <div class="mb-2">

              <Button class="p-button-danger" icon="pi pi-trash" @click="confirmDeleteDocOrders(data.id)"/>

            </div>
            <a class="btn btn-primary" target="_blank" role="button"
               :href="`https://back.uib.kz/doc_orders/doc-orders-print/print-orders?doc_orders_id=${data.id}`">
              <i class="fa fa-solid fa-print"></i>
            </a>

          </template>


        </Column>


      </DataTable>



      <Dialog v-model:visible="addDocOrdersDisplay" :style="{width: '50vw'}">
        <template #header>
          <h5>Добавить приказ</h5>
        </template>

        <div class="col-md-12 mt-4">
          <div class="form-group row mt-4">
            <label for="order_number" class="col-md-3 col-form-label">Номер приказа</label>
            <div class="col-md-9">
              <input id="order_number" type="text" class="form-control"
                     placeholder="Номер приказа" @input="changeNewDocOrdersData( 'order_number', $event)">
            </div>
          </div>


          <div class="form-group row mt-4">
            <label class="col-md-3 col-form-label">Тип приказа</label>
            <div class="col-md-9">
              <select class="form-control form-select"
                      @input="changeNewDocOrdersData( 'doc_order_type_id', $event)">
                <option
                    v-for="(item, index) in [{id: 0, name_ru: 'Выберите тип'}, ...docOrders_form.docOrderType]"
                    :value="item.id"
                    :key="index">{{ item.name_ru }}
                </option>
              </select>
            </div>
          </div>

          <div class="form-group row mt-4" v-if="getDocOrderSubType.length !== 0">
            <label class="col-md-3 col-form-label">Подтип приказа</label>
            <div class="col-md-9">
              <select class="form-control form-select"
                      @input="changeNewDocOrdersData( 'doc_order_sub_type_id', $event)">
                <option
                    v-for="(item, index) in [{id: 0, name_ru: 'Выберите подтип'}, ...getDocOrderSubType]"
                    :value="item.id"
                    :key="index">{{ item.name_ru }}
                </option>
              </select>
            </div>
          </div>

          <div class="form-group row mt-4">
            <label class="col-md-3 col-form-label">Текст приказа (на русском)</label>
            <div class="col-md-9">
              <textarea class="form-control" placeholder="Текст приказа (на русском)"
                        @input="changeNewDocOrdersData( 'order_text_ru', $event)">
              </textarea>
            </div>
          </div>

          <div class="form-group row mt-4">
            <label class="col-md-3 col-form-label">Текст приказа (на казахском)</label>
            <div class="col-md-9">
              <textarea class="form-control" placeholder="Текст приказа (на казахском)"
                        @input="changeNewDocOrdersData( 'order_text_kz', $event)">
              </textarea>
            </div>
          </div>

          <div class="form-group row mt-4">
            <label class="col-md-3 col-form-label">Причина/основание (на русском)</label>
            <div class="col-md-9">
              <textarea class="form-control" placeholder="Причина/основание (на русском)"
                        @input="changeNewDocOrdersData( 'order_reason_ru', $event)">
              </textarea>
            </div>
          </div>

          <div class="form-group row mt-4">
            <label class="col-md-3 col-form-label">Причина/основание (на казахском)</label>
            <div class="col-md-9">
              <textarea class="form-control" placeholder="Причина/основание (на казахском)"
                        @input="changeNewDocOrdersData( 'order_reason_kz', $event)">
              </textarea>
            </div>
          </div>

          <div class="form-group row mt-4">
            <label for="order_note_ru" class="col-md-3 col-form-label">Примечание (на русском)</label>
            <div class="col-md-9">
              <input id="order_note_ru" type="text" class="form-control"
                     placeholder="Примечание (на русском)" @input="changeNewDocOrdersData( 'order_note_ru', $event)">
            </div>
          </div>
          <div class="form-group row mt-4">
            <label for="order_note_kz" class="col-md-3 col-form-label">Примечание (на казахском)</label>
            <div class="col-md-9">
              <input id="order_note_kz" type="text" class="form-control"
                     placeholder="Примечание (на казахском)" @input="changeNewDocOrdersData( 'order_note_kz', $event)">
            </div>
          </div>

          <div class="form-group row mt-4">
            <label class="col-md-3 col-form-label">Учебный год</label>
            <div class="col-md-9">
              <select class="form-control form-select" id="academic_year"
                      @input="changeNewDocOrdersData( 'academic_year_id', $event)">
                <option
                    v-for="(item, index) in [{id: 0, name: 'Выберите учебный год'}, ...docOrders_form.academicYear]"
                    :value="item.id"
                    :key="index">{{ item.name }}
                </option>
              </select>
            </div>
          </div>


          <div class="form-group row mt-4">
            <label for="order_date" class="col-md-3 col-form-label">Дата приказа</label>
            <div class="col-md-9">
              <input id="order_date" type="date" class="form-control"
                     @input="changeNewDocOrderDate('doc_order_date', $event)">
            </div>
          </div>



        </div>

        <template #footer>
          <Button label="Закрыть" icon="pi pi-times" class="p-button-text"
                  @click="closeAddDocOrdersDialog"/>
          <Button label="Добавить" icon="pi pi-check" @click="postDocOrders"/>
        </template>
      </Dialog>


      <Dialog v-model:visible="editDocOrdersDisplay" :style="{width: '50vw'}">
        <template #header>
          <h5>Редактирование приказа</h5>
        </template>

        <div class="col-md-12 mt-4">
          <div class="form-group row mt-4">
            <label for="up_order_number" class="col-md-3 col-form-label">Номер приказа</label>
            <div class="col-md-9">
              <input id="up_order_number" type="text" class="form-control"
                     :value="editedDocOrders.order_number" placeholder="Номер приказа"
                     @input="changeDocOrdersData(UPDATE_ITEM, 'order_number', $event)">
            </div>
          </div>


<!--          <div class="form-group row mt-4">-->
<!--            <label class="col-md-3 col-form-label">Тип приказа</label>-->
<!--            <div class="col-md-9">-->
<!--              <select class="form-control form-select"-->
<!--                      @input="changeDocOrdersData(UPDATE_ITEM, 'doc_order_type_id', $event)">-->
<!--                <option-->
<!--                    v-for="(item, index) in docOrders_form.docOrderType"-->
<!--                    :value="item.id"-->
<!--                    :selected="item.id == docOrders_form.docOrdersInfo.find(i=>i.id == editDocOrdersId).doc_order_type_id"-->
<!--                    :key="index">{{ item.name_ru }}-->
<!--                </option>-->
<!--              </select>-->
<!--            </div>-->
<!--          </div>-->


          <div class="form-group row mt-4" v-if="docOrders_form.docOrdersInfo.find(i=>i.id == editDocOrdersId) &&
          getDocOrderSubTypeForEdit.length > 0 && (editedDocOrders.doc_order_type_id == 3 || editedDocOrders.doc_order_type_id == 4)">
            <label class="col-md-3 col-form-label">Подтип приказа</label>
            <div class="col-md-9">
              <select class="form-control form-select"
                      v-model="editedDocOrders.doc_order_sub_type_id"
                      @input="changeDocOrdersData(UPDATE_ITEM, 'doc_order_sub_type_id', $event)">
                <option
                    v-for="(item, index) in [{id: 0, name_ru: 'Выберите подтип'}, ...getDocOrderSubTypeForEdit]"
                    :value="item.id"
                    :key="index">{{ item.name_ru }}
                </option>
              </select>
            </div>
          </div>

          <div class="form-group row mt-4">
            <label class="col-md-3 col-form-label">Текст приказа (на русском)</label>
            <div class="col-md-9">
              <textarea class="form-control" placeholder="Текст приказа (на русском)"
                        :value="editedDocOrders.order_text_ru"
                        @input="changeDocOrdersData(UPDATE_ITEM, 'order_text_ru', $event)">
              </textarea>
            </div>
          </div>

          <div class="form-group row mt-4">
            <label class="col-md-3 col-form-label">Текст приказа (на казахском)</label>
            <div class="col-md-9">
              <textarea class="form-control" placeholder="Текст приказа (на казахском)"
                        :value="editedDocOrders.order_text_kz"
                        @input="changeDocOrdersData(UPDATE_ITEM, 'order_text_kz', $event)">
              </textarea>
            </div>
          </div>

          <div class="form-group row mt-4">
            <label class="col-md-3 col-form-label">Причина/основание (на русском)</label>
            <div class="col-md-9">
              <textarea class="form-control" placeholder="Причина/основание (на русском)"
                        :value="editedDocOrders.order_reason_ru"
                        @input="changeDocOrdersData( UPDATE_ITEM,'order_reason_ru', $event)">
              </textarea>
            </div>
          </div>

          <div class="form-group row mt-4">
            <label class="col-md-3 col-form-label">Причина/основание (на казахском)</label>
            <div class="col-md-9">
              <textarea class="form-control" placeholder="Причина/основание (на казахском)"
                        :value="editedDocOrders.order_reason_kz"
                        @input="changeDocOrdersData(UPDATE_ITEM, 'order_reason_kz', $event)">
              </textarea>
            </div>
          </div>

          <div class="form-group row mt-4">
            <label for="up_order_note_ru" class="col-md-3 col-form-label">Примечание (на русском)</label>
            <div class="col-md-9">
              <input id="up_order_note_ru" type="text" class="form-control"
                     :value="editedDocOrders.order_note_ru"
                     placeholder="Примечание (на русском)"
                     @input="changeDocOrdersData(UPDATE_ITEM, 'order_note_ru', $event)">
            </div>
          </div>
          <div class="form-group row mt-4">
            <label for="up_order_note_kz" class="col-md-3 col-form-label">Примечание (на казахском)</label>
            <div class="col-md-9">
              <input id="up_order_note_kz" type="text" class="form-control"
                     :value="editedDocOrders.order_note_kz"
                     placeholder="Примечание (на казахском)"
                     @input="changeDocOrdersData(UPDATE_ITEM, 'order_note_kz', $event)">
            </div>
          </div>

          <div class="form-group row mt-4">
            <label class="col-md-3 col-form-label">Учебный год</label>
            <div class="col-md-9">
              <select class="form-control form-select" id="up_academic_year"
                      @input="changeDocOrdersData(UPDATE_ITEM, 'academic_year_id', $event)">
                <option
                    v-for="(item, index) in [{id: 0, name: 'Выберите учебный год'}, ...docOrders_form.academicYear]"
                    :value="item.id"
                    :selected="item.id == editedDocOrders.academic_year_id"
                    :key="index">{{ item.name }}
                </option>
              </select>
            </div>
          </div>


          <div class="form-group row mt-4">
            <label for="doc_order_date" class="col-md-3 col-form-label">Дата приказа</label>
            <div class="col-md-9">
              <input id="doc_order_date" type="date" class="form-control"
                     :value="convertTimestampToDate(editedDocOrders.doc_order_date)"
                     @input="changeDocOrderDate(UPDATE_ITEM,'doc_order_date', $event)">
            </div>
          </div>

        </div>

        <template #footer>
          <Button label="Закрыть" icon="pi pi-times" class="p-button-text" @click="closeEditDocOrdersDialog"/>
          <Button label="Обновить" icon="pi pi-check" @click="putDocOrders"/>
        </template>
      </Dialog>



      <ConfirmDialog></ConfirmDialog>
    </div>
  </div>


</template>

<script>
import {FilterMatchMode, FilterOperator} from 'primevue/api';
import {mapGetters, mapActions, mapMutations, mapState} from "vuex";
import {convertTimestampToDate} from "@/utils/helpers/convertTimestampToDate"

export default {
  name: "DocOrders",


  data() {
    return {
      filters: {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
        'docOrderType.name_ru': {value: null, matchMode: FilterMatchMode.EQUALS},
        'docOrderSubType.name_ru': {value: null, matchMode: FilterMatchMode.EQUALS},
      },
      addDocOrdersDisplay: false,
      editDocOrdersDisplay: false,
      selectedDocOrderTypeId: null,
      editedDocOrders: [],
      editDocOrdersId: 0
    }
  },
  computed: {
    ...mapState('docOrders', ['docOrders_form']),
    ...mapGetters('docOrders', ['UPDATE_ITEM']),
    getDocOrderSubType(){
      let docOrderSubType = this.docOrders_form.docOrderSubType.filter(i => i.doc_order_type_id == this.docOrders_form.newDocOrdersInfo.doc_order_type_id)
      console.log(docOrderSubType, 'docOrderSubType')
      return docOrderSubType
    },

    getDocOrderSubTypeForEdit(){
      let docOrderSubTypeForEdit = this.docOrders_form.docOrderSubType.filter(i => i.doc_order_type_id == this.editedDocOrders.doc_order_type_id)
      console.log(docOrderSubTypeForEdit, 'docOrderSubTypeForEdit')
      return docOrderSubTypeForEdit
    }
  },

  methods: {
    ...mapActions('docOrders', ['GET_SP_ACADEMIC_YEAR','GET_SP_DOC_ORDER_TYPE', 'DELETE_DOC_ORDERS',
      'GET_SP_DOC_ORDER_SUB_TYPE', 'GET_DOC_ORDERS', 'POST_DOC_ORDERS', 'PUT_DOC_ORDERS']),

    ...mapMutations('docOrders', ['SET_NEW_DOC_ORDERS_DATA', 'SET_NEW_DOC_ORDER_DATE',
      'SET_EDIT_DOC_ORDERS_ID', 'UPDATE_DOC_ORDERS_DATA', 'SET_DOC_ORDER_DATE']),

    convertTimestampToDate,
    openAddDocOrdersDialog() {
      this.addDocOrdersDisplay = true
    },
    closeAddDocOrdersDialog() {
      this.addDocOrdersDisplay = false
    },
    openEditDocOrdersDialog(id) {
      this.editDocOrdersDisplay = true
      this.editDocOrdersId = id
      this.editedDocOrders = this.docOrders_form.docOrdersInfo.find(i=>i.id == id)
      console.log(this.editedDocOrders, 'editedDocOrders')
      this.SET_EDIT_DOC_ORDERS_ID(id)
    },
    closeEditDocOrdersDialog(){
      this.editDocOrdersDisplay = false
    },

    changeNewDocOrdersData(property, e, val = 'value') {
      const value = e.target[val]
      this.SET_NEW_DOC_ORDERS_DATA({property, value})
    },
    changeDocOrdersData(item, property, e, val = 'value') {
      const value = e.target[val]
      this.UPDATE_DOC_ORDERS_DATA({item, property, value})
    },
    changeNewDocOrderDate(property, e) {
      this.SET_NEW_DOC_ORDER_DATE({property, value: e.target.value})
    },
    changeDocOrderDate(item, property, e) {
      this.SET_DOC_ORDER_DATE({item, property, value: e.target.value})
    },
    async postDocOrders(){
      this.addDocOrdersDisplay = false
      await this.POST_DOC_ORDERS()
      await this.GET_DOC_ORDERS()
    },
    async putDocOrders(){
      this.editDocOrdersDisplay = false
      await this.PUT_DOC_ORDERS()
      await this.GET_DOC_ORDERS()
    },
    confirmDeleteDocOrders(id) {
      this.$confirm.require({
        message: 'Вы уверены что хотите удалить приказ?',
        header: 'Удаление приказа',
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {

          await this.DELETE_DOC_ORDERS(id)
          await this.GET_DOC_ORDERS()
        }
      });
    },



  },
  async mounted() {
    await this.GET_DOC_ORDERS()
    await this.GET_SP_ACADEMIC_YEAR()
    await this.GET_SP_DOC_ORDER_TYPE()
    await this.GET_SP_DOC_ORDER_SUB_TYPE()

  }
}
</script>

<style scoped>


</style>
